import moment from "moment/moment";

export function getExecutionString(
  serviceReceiptItem:
    | {
      executionStartDate: string | null | undefined;
      executionEndDate: string | null | undefined;
      executionStartTime: string | null | undefined;
      executionEndTime: string | null | undefined;
      }
    | null
    | undefined
) {
    if (!serviceReceiptItem) {
        return '';
    }
    let executionString = "";
    if (serviceReceiptItem.executionStartDate) {
        executionString += moment(serviceReceiptItem.executionStartDate as string).format('DD.MM.YYYY');
        if (serviceReceiptItem.executionStartTime) {
            executionString += " " + moment(serviceReceiptItem.executionStartTime as string, 'HH:mm:ss').format('HH:mm');
        }
    }
    if (serviceReceiptItem.executionEndDate) {
        if (serviceReceiptItem.executionEndTime) {
            if (serviceReceiptItem.executionStartDate !== serviceReceiptItem.executionEndDate) {
                executionString += " - " + moment(serviceReceiptItem.executionEndDate as string).format('DD.MM.YYYY') +
                    " " + moment(serviceReceiptItem.executionEndTime as string, 'HH:mm:ss').format(' HH:mm');
            } else {
                executionString += " - " + moment(serviceReceiptItem.executionEndTime as string, 'HH:mm:ss').format(' HH:mm');
            }
        } else if (serviceReceiptItem.executionStartDate !== serviceReceiptItem.executionEndDate) {
            executionString += " - " + moment(serviceReceiptItem.executionEndDate as string).format('DD.MM.YYYY')
        }
    }

    if (executionString === "") {
        return null;
    } else {
        return executionString;
    }
}
